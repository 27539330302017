import React from "react";
import css from './tags.module.scss';

const Tags = props => {
  const { stringTags = '' } = props;

  const styles = {
    "technology": {
      color: '#fff',
      background: '#8E3E9D'
    },
    "society": {
      color: '#fff',
      background: '#eabc14'
    },
    // "sample tag 01": {
    //   color: 'var(--mainBlackColor)',
    //   background: 'gold'
    // }
  };

  const stylesDefault = {
    color: "#fff",
    background: "rgba(133, 214, 66, 1)"
  };

  const tagsArray = stringTags
    .split("|")
    .map((tag, index) => {
      const newTag = tag.trim();
      if (!newTag) {
        return null;
      }
      return (
        <span
          key={`${index}_${newTag}`}
          className={css.tag}
          style={styles[newTag] || stylesDefault}
        >
          {newTag}
        </span>
      )
  });

  return (
    <div className={css.tagsWrapper}>
      {tagsArray}
    </div>
  )
};

export default Tags;
