import React from "react";
import css from './newsDate.module.scss';
import Clock from '../../../images/icons/clock.svg';

const NewsDate = props => {
  const { date, showClock = true } = props;

  return (
    <div className={css.wrapper}>
      { showClock && <div className={css.clock}><img src={Clock} alt="" /></div> }
      <div className={css.date}>{date}</div>
    </div>
  )
};

export default NewsDate;
