import React from 'react';
import { viewports } from "../../componentsUtils";
import css from './boxHeader.module.scss';
import useBreakpoint from "../../hooks/useBreakpoint";

const BoxHeader = (props) => {
  const { tahuFont, bigger, contentAligned = true } = props;
  const tahuFontClass = !!tahuFont ? css.tahuFont : null;
  const biggerClass = !!bigger ? css.bigger : null;
  const { phoneViewport, tabletViewport, desktopViewport } = viewports;
  const breakpoint = useBreakpoint();
  const tabletViewportClass = (breakpoint.viewport === tabletViewport) ? css.tabletViewport : null;
  const phoneViewportClass = (breakpoint.viewport === phoneViewport) ? css.phoneViewport : null;
  const contentAlignedClass = contentAligned ? css.contentAligned : null;
  const headerClassName = [css.header, tahuFontClass, biggerClass, phoneViewportClass, tabletViewportClass, contentAlignedClass].join(' ');

  const getLineColorClass = () => {
    const lineColor = !!props.lineColor ? props.lineColor : 'mainRed';
    switch (lineColor) {
      case 'transparent':
        return '';
      default:
        return css.lineGreen;
    }
  };

  const content = (<span className={[css.content, getLineColorClass()].join(' ')}>{props.children}</span>);

  switch (props.headerType) {
    case 'h1':
      return (<h1 className={headerClassName} style={props.style}>{content}</h1>);
    case 'h3':
      return (<h3 className={headerClassName} style={props.style}>{content}</h3>);
    default:
      return (<h2 className={headerClassName} style={props.style}>{content}</h2>);
  }
};

export default BoxHeader;
